import request from '@/utils/request'

// 获取下载详情
export function downloadDetail(params) {
    return request({
        url: '/qy/manager-product-serial-number/detail',
        method: 'get',
        params,
    })
}
// 产品列表
export function serialNumberDownload(params) {
    return request({
        url: '/qy/manager-product-serial-number/serialNumberDownloadFinish',
        method: 'get',
        params,
    })
}